<template>
  <template v-if="item">
    <div class="fk-block">
      <div class="grid grid-cols-1 lg:grid-cols-[1fr_500px] gap-base">
        <!-- Text -->
        <div class="fk-prose">
          <!-- jobDescription -->
          <template
            v-if="
              item.attributes.jobDescriptionTitle &&
              item.attributes.jobDescription
            "
          >
            <h2>{{ item.attributes.jobDescriptionTitle }}</h2>
            <div :class="{ 'line-clamp-3': !showFullDescription }">
              <!-- TODO: Class .markdown toegevoegd aan tailwind.css voor padding bottom op <p> -->
              <!-- TODO: transition expand werkt niet meer op MDC component. misschien <div>-wrapper omheen zetten -->
              <!-- @see https://nuxt.com/modules/mdc -->
              <MDC class="markdown" :value="item.attributes.jobDescription" />
            </div>
            <div
              v-if="!showFullDescription"
              class="underline hover:no-underline mt-2 cursor-pointer"
              @click="showFullDescription = true"
            >
              {{ $t("general.readMore") }}
            </div>
          </template>

          <!-- jobTasksDescription -->
          <template
            v-if="
              item.attributes.jobTasksDescriptionTitle &&
              item.attributes.jobTasksDescription
            "
          >
            <HeadlessDisclosure
              v-slot="{ open }"
              as="div"
              class="border-b border-dark/20 pb-4 mt-6"
            >
              <HeadlessDisclosureButton
                as="h2"
                class="select-none cursor-pointer flex gap-4 justify-between"
                >{{ item.attributes.jobTasksDescriptionTitle }}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  class="w-3 fill-current flex-none transition-transform"
                  :class="{ 'rotate-180': open }"
                >
                  <path
                    d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                  />
                </svg>
              </HeadlessDisclosureButton>
              <TransitionExpand no-opacity>
                <HeadlessDisclosurePanel :unmount="false">
                  <MDC
                    class="markdown"
                    :value="item.attributes.jobTasksDescription"
                  />
                </HeadlessDisclosurePanel>
              </TransitionExpand>
            </HeadlessDisclosure>
          </template>

          <!-- jobCandidateDescription -->
          <template
            v-if="
              item.attributes.jobCandidateDescriptionTitle &&
              item.attributes.jobCandidateDescription
            "
          >
            <HeadlessDisclosure
              v-slot="{ open }"
              as="div"
              class="border-b border-dark/20 pb-4 mt-6"
            >
              <HeadlessDisclosureButton
                as="h2"
                class="select-none cursor-pointer flex gap-4 justify-between"
                >{{ item.attributes.jobCandidateDescriptionTitle }}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  class="w-3 fill-current flex-none transition-transform"
                  :class="{ 'rotate-180': open }"
                >
                  <path
                    d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                  />
                </svg>
              </HeadlessDisclosureButton>
              <TransitionExpand no-opacity>
                <HeadlessDisclosurePanel :unmount="false">
                  <MDC
                    class="markdown"
                    :value="item.attributes.jobCandidateDescription"
                  />
                </HeadlessDisclosurePanel>
              </TransitionExpand>
            </HeadlessDisclosure>
          </template>

          <!-- jobOfferDescription -->
          <template
            v-if="
              item.attributes.jobOfferDescriptionTitle &&
              item.attributes.jobOfferDescription
            "
          >
            <HeadlessDisclosure
              v-slot="{ open }"
              as="div"
              class="border-b border-dark/20 pb-4 mt-6"
            >
              <HeadlessDisclosureButton
                as="h2"
                class="select-none cursor-pointer flex gap-4 justify-between"
                >{{ item.attributes.jobOfferDescriptionTitle }}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  class="w-3 fill-current flex-none transition-transform"
                  :class="{ 'rotate-180': open }"
                >
                  <path
                    d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                  />
                </svg>
              </HeadlessDisclosureButton>
              <TransitionExpand no-opacity>
                <HeadlessDisclosurePanel :unmount="false">
                  {{ item.attributes.jobOfferDescription }}
                  <MDC
                    class="markdown"
                    :value="item.attributes.jobOfferDescription"
                  />
                </HeadlessDisclosurePanel>
              </TransitionExpand>
            </HeadlessDisclosure>
          </template>

          <p
            v-if="item.attributes.disableAcquisitionMessage"
            class="mt-base-1/2 italic text-sm"
          >
            {{ $t("general.disableAcquisitionMessage") }}.
          </p>

          <VacancyShareAndApply
            class="flex flex-wrap justify-center gap-x-2 items-center mt-8 sticky lg:static bottom-4 [&>div:nth-child(1)]:hidden lg:[&>div:nth-child(1)]:block"
            :vacancy-title="item.attributes.title"
            :vacancy-status="item.attributes.status"
            :vacancy-external-apply-url="item.attributes.externalApplyUrl"
          />

          <VacancyShareAndApply
            class="flex flex-wrap justify-center mt-2 [&>*:nth-child(2)]:hidden lg:hidden"
            :vacancy-title="item.attributes.title"
            :vacancy-status="item.attributes.status"
            :vacancy-external-apply-url="item.attributes.externalApplyUrl"
          />

          <!-- Back button-->
          <div class="text-center">
            <div class="btn btn-link" @click="goBack">
              {{ $t("general.back") }}
            </div>
          </div>
        </div>

        <!-- Sidebar -->
        <div>
          <VacancyContact
            :open-application="false"
            :first-name="item.attributes.contactInfo?.firstName"
            :infix="item.attributes.contactInfo?.infix"
            :last-name="item.attributes.contactInfo?.lastName"
            :phone="item.attributes.contactInfo?.phone"
            :email="item.attributes.contactInfo?.email"
            :email-subject="`${item.attributes.title} in ${
              item.attributes.places.data[0]?.attributes?.region?.data
                ?.attributes?.title
            }`"
          />
          <iframe
            v-if="item.attributes.youtubeVideo"
            class="w-full aspect-video mt-8"
            :src="
              `https://www.youtube.com/embed/` +
              formatYouTubeVideo(item.attributes.youtubeVideo)
            "
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>

    <section class="fk-block">
      <div>
        <!-- Vacancies in region -->

        <FkStrapiCollection
          :collection="'vacancy'"
          :filters="{
            id: {
              $not: item.id,
            },
            status: {
              $eq: 'open',
            },
            professions: { id: vacancyProfessionsIds },
            places: { region: { id: vacancyRegionIds } },
          }"
          :pagination="{
            page: 1,
            pageSize: 3,
          }"
          :use-pagination="false"
          :use-filter-bar="false"
          sort="expiresAt:desc"
        >
          <template #prepend>
            <div class="fk-prose text-center mb-base">
              <h2>{{ $t("general.vacanciesInRegion") }}</h2>
            </div>
          </template>

          <!-- No Vacancies in region found -->
          <template #empty>
            <!-- Vacancies in profession -->
            <FkStrapiCollection
              :collection="'vacancy'"
              :filters="{
                id: {
                  $not: item.id,
                },
                status: {
                  $eq: 'open',
                },
                professions: { id: vacancyProfessionsIds },
              }"
              :pagination="{
                page: 1,
                pageSize: 3,
              }"
              :use-pagination="false"
              :use-filter-bar="false"
              sort="expiresAt:desc"
            >
              <template #prepend>
                <div class="fk-prose text-center mb-base">
                  <h2>{{ $t("general.vacanciesInProfession") }}</h2>
                </div>
              </template>

              <!-- No Vacancies in profession found -->
              <template #empty>&nbsp;</template>
            </FkStrapiCollection>
          </template>
        </FkStrapiCollection>
        <div class="text-center mt-base">
          <!-- TODO: /vacatures should be i18n -->
          <NuxtLink :to="`/vacatures`" class="btn">
            {{ $t("general.viewAllVacancies") }}
          </NuxtLink>
        </div>
      </div>
    </section>
  </template>
</template>

<script setup lang="ts">
const showFullDescription = ref(false);
const { itemKey } = useInjectionKeys();
const item = inject(itemKey);
const router = useRouter();
const localePath = useLocalePath();

const vacancyProfessionsIds = computed(() => {
  return item?.attributes.professions.data.map(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (profession: any) => profession.id,
  );
});

const vacancyRegionIds = computed(() => {
  return item?.attributes.places.data.map(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (place: any) => place.attributes.region.data.id,
  );
});

useSeoMeta({
  title: item?.attributes?.title,
  description: item?.attributes?.jobDescription,
  viewport: "width=device-width, initial-scale=1.0",

  ogTitle: item?.attributes?.title,
  ogDescription: item?.attributes?.jobDescription,
  ogImage:
    item?.attributes?.shareImage?.data?.attributes?.url ??
    item?.attributes?.headerImage?.data?.attributes?.url ??
    item?.attributes?.company?.data?.attributes?.headerImage?.data?.attributes
      ?.url,
  ogType: "website",
  ogSiteName: "",
  ogUrl: "",
});

// Structured Data
const datePosted = new Date(item?.attributes?.expiresAt);
datePosted.setDate(datePosted.getDate() - 60);
// TODO: https://schema.org/JobPosting

callOnce(() => {
  if (item?.attributes.status !== "open") {
    return;
  }
  useJsonld(() => ({
    "@context": "https://schema.org",
    "@type": "JobPosting",
    validThrough: new Date(item?.attributes?.expiresAt).toISOString(),

    title: item?.attributes?.title,
    // TODO: Add all the fields
    hiringOrganization: {
      "@type": "Organization",
      name: item?.attributes?.company?.data?.attributes?.title,
    },
    baseSalary: item?.attributes.monthlySalaryFrom
      ? {
          "@type": "MonetaryAmount",
          currency: "EUR",
          value: {
            "@type": "QuantitativeValue",
            minValue: `${item?.attributes.monthlySalaryFrom}`,
            maxValue: `${item?.attributes.monthlySalaryTo}`,
          },
        }
      : item?.attributes.hourlySalaryFrom
        ? {
            "@type": "MonetaryAmount",
            currency: "EUR",
            value: {
              "@type": "QuantitativeValue",
              minValue: `${item?.attributes.hourlySalaryFrom}`,
              maxValue: `${item?.attributes.hourlySalaryTo}`,
            },
          }
        : item?.attributes.salaryDescription
          ? {
              "@type": "MonetaryAmount",
              currency: "EUR",
              value: item?.attributes.salaryDescription,
            }
          : undefined,

    jobBenefits: item?.attributes.jobBenefits,
    datePosted: datePosted.toISOString(),
    description: item?.attributes?.jobDescription,
    educationRequirements: item?.attributes.educations?.data
      ?.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (ed: any) => ed.attributes.title,
      )
      .join(", "),
    employmentType: item?.attributes.employments?.data
      ?.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (ed: any) => ed.attributes.title,
      )
      .join(", "),
    // experienceRequirements: "Minumum 3 years experience as a software engineer",
    // incentiveCompensation:
    //   "Performance-based annual bonus plan, project-completion bonuses",
    industry: item?.attributes.professions?.data
      ?.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (ed: any) => ed.attributes.title,
      )
      .join(", "),
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        addressLocality: item?.attributes.places?.data[0]?.attributes?.title,
        addressRegion:
          item?.attributes.places?.data[0]?.attributes?.region?.data?.attributes
            ?.title,
      },
    },
    // occupationalCategory: "15-1132.00 Software Developers, Application",
    qualifications: item?.attributes.jobCandidateDescription,
    responsibilities: item?.attributes.jobTasksDescription,
    salaryCurrency: "EUR",
    // skills:
    //   "Web application development using Java/J2EE Web application development using Python or familiarity with dynamic programming languages",
    // specialCommitments: "VeteranCommit",
    workHours: `${item?.attributes.hoursFrom} - ${item?.attributes.hoursTo}`,
  }));
});

useHead(() => {
  return {
    meta: [
      {
        name: "title",
        content: item?.attributes.title,
      },
    ],
  };
});

const goBack = () => {
  if (window.history.length > 1) {
    router.go(-1);
  } else {
    router.push(localePath("/vacatures"));
  }
};
</script>

<style class="css" scoped>
:deep(.items) {
  @apply grid gap-4;
}
</style>
